import recipes from "./RecipeData";

export default function RecipeDetail() {

    return (
        <div>
            <ul>
                {recipes.map((recipe, index) => (
                    <div key={index}>
                        <li>
                            {/* <span>{recipe.name} - {recipe.prepTime}</span> */}
                            <a href={`/recipe/${index}`}>{recipe.name}</a> <span> - {recipe.cookingTime}</span>
                        </li>
                    </div>
                ))}
            </ul>
        </div>
    );
}