import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import { Home, Recipe } from './pages';
import Home from './pages/Home';
import Recipe from './pages/Recipe';


function App() {
  return (
  <div>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/recipe/:id" element={<Recipe />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
};

export default App;
