import RecipeDetail from "../components/RecipeDetail";
import { useParams } from 'react-router-dom'

export default function Recipe() {
    const { id } = useParams()
    console.log('Recipes:', id);
    return (
        <>
            <RecipeDetail recipeId={id} />
        </>
    );
}