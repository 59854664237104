import recipes from "./RecipeData";

export default function RecipeDetail({ recipeId }) {
    const recipeDetail = recipes[recipeId];

    return (
        <div>
            <p>Food: {recipeDetail.name}</p>
            <p>Method: {recipeDetail.method}</p>
            <p>Cooking Time: {recipeDetail.cookingTime}</p>
            <p>Cooking Temp: {recipeDetail.cookingTemp}</p>
            {recipeDetail.targetTemp && <p>Target Temp: {recipeDetail.targetTemp}</p>}
            <p>Notes: {recipeDetail.instructions}</p>
        </div>
    );
}